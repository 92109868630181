import React, {useState} from 'react';
import './popularItems.css';
import img1 from '../../assets/img/catsDryFood/cat sausas 1.webp';
import img2 from '../../assets/img/catsDryFood/cat sausas 10.webp';
import img3 from '../../assets/img/dogsCannedFood/dog konservai 6.webp';
import img4 from '../../assets/img/dogsDryFood/dog sausas 10.webp';
import img5 from '../../assets/img/catsCannedFood/cat konservai 4.webp';
import img6 from '../../assets/img/dogsDryFood/dog sausas 11.webp';
import logo from '../../assets/img/mobileMain/brand logos.webp';
import division from '../../assets/img/mobileMain/division section.webp';
import divisionEN from '../../assets/img/mobileMain/division-section-EN-mobile.webp';
import nose from '../../assets/img/mobileMain/nose 1.webp';
import Item from './Item';
import {useSelector} from 'react-redux';
import SlideComp from './SlideComp';
import dogsDryFoodArrLT from '../../helpers/LT/dogsDryFoodLT.js';
import dogsCannedFoodArrLT from '../../helpers/LT/dogsCannedFoodLT.js';
import catsDryFoodArrLT from '../../helpers/LT/catsDryFoodLT.js';
import catsCannedFoodArrLT from '../../helpers/LT/catsCannedFoodLT.js';
import dogsDryFoodArrEN from '../../helpers/EN/dogsDryFoodEN.js';
import dogsCannedFoodArrEN from '../../helpers/EN/dogsCannedFoodEN.js';
import catsDryFoodArrEN from '../../helpers/EN/catsDryFoodEN.js';
import catsCannedFoodArrEN from '../../helpers/EN/catsCannedFoodEN.js';
import newsImg1 from '../../assets/img/newsAndOffers/cat-shampoo.webp';
import newsImg2 from '../../assets/img/newsAndOffers/dog-shampoo1.webp';
import newsImg3 from '../../assets/img/newsAndOffers/dog-shampoo2.webp';
import newsImg4 from '../../assets/img/newsAndOffers/dog-paste.webp';
import newsImg5 from '../../assets/img/newsAndOffers/spray.webp';
import newsImg6 from '../../assets/img/newsAndOffers/cologne.webp';

function PopularItems() {
	const {pageLanguage} = useSelector((state) => state.generalSlice);

	const items = [
		{
			img: img1,
			desc: pageLanguage === 'LT' ? 'Advance Sensitive sausas ėdalas suagusioms sterilizuotoms katėms' : 'Advance Sensitive Dry Food for Adult Sterilized Cats',
			price: '7.10/kg',
			hot: false,
			new: false,
			fullDesc: pageLanguage === 'LT' ? catsDryFoodArrLT[0].fullDesc : catsDryFoodArrEN[0].fullDesc,
		},
		{
			img: img2,
			desc: pageLanguage === 'LT' ? 'AATU sausas ėdalas katėms su laisvai bėgiojančių ančių mėsa' : 'AATU Free Run Duck Dry Food for Cats',
			price: '44.00 (3 kg)',
			hot: false,
			new: false,
			fullDesc: pageLanguage === 'LT' ? catsDryFoodArrLT[9].fullDesc : catsDryFoodArrEN[9].fullDesc,
		},
		{
			img: img3,
			desc: pageLanguage === 'LT' ? 'Barking Heads Puppy Days konservai šuniukams' : 'Barking Heads Puppy Days Wet Puppy Food',
			price: '3.50 (300 g)',
			hot: false,
			new: false,
			fullDesc: pageLanguage === 'LT' ? dogsCannedFoodArrLT[5].fullDesc : dogsCannedFoodArrEN[5].fullDesc,
		},
		{
			img: img4,
			desc: pageLanguage === 'LT' ? 'Calibra Veterinary Diet sausas ėdalas šunims su inkstų nepakankamumu ir širdies sutrikimais' : 'Calibra Veterinary Diet Renal & Cardiac Dry Food for Dogs',
			price: '16.65 (2 kg)',
			hot: false,
			new: false,
			fullDesc: pageLanguage === 'LT' ? dogsDryFoodArrLT[9].fullDesc : dogsDryFoodArrEN[9].fullDesc,
		},
		{
			img: img5,
			desc: pageLanguage === 'LT' ? 'Aatas Cat viliojantis konservuotas tunas ir krevetės drebučiuose (12 skirtingų rūšių)' : 'Aatas Cat Tantalizing Canned Tuna & Shrimp in Aspic for Cats (12 different options)',
			price: '1.20 (80 g)',
			hot: false,
			new: false,
			fullDesc: pageLanguage === 'LT' ? catsCannedFoodArrLT[3].fullDesc : catsCannedFoodArrEN[3].fullDesc,
		},
		{
			img: img6,
			desc: pageLanguage === 'LT' ? 'Brit Care sausas elnienos ir bulvių ėdalas be grūdų jautriems ir maisto netoleruojantiems šunims' : 'Brit Care Grain-Free Venison & Potatoes Dry Food for Sensitive Dogs and Dogs with Intolerance',
			price: '6.30/kg',
			hot: false,
			new: false,
			fullDesc:
				pageLanguage === 'LT'
					? {
							analysis: ['Žali baltymai 25%', 'Žali riebalai 14%', 'Žali pelenai 8.5%', 'Žalia ląsteliena 3%', 'Drėgnis 10%', 'Kalcis 1.7%', 'Fosforas 1.2%', 'Natris 0.2%', 'Omega-3 riebalų rūgštys 1%', 'Omega-6 riebalų rūgštys 0.8%', 'EPA (20:5 n-3) 0.06%', 'DHA (22:6 n-3) 0.12%'],
							vitPerKg: ['Vitaminas A (3a672a) 23000 TV', 'Vitaminas D3 (3a671) 1700 TV', 'Vitaminas E (3a700) 550 mg', 'Vitaminas C (3a312) 350 mg', 'Taurinas (3a370) 1500 mg', 'Cholino chloridas (3a890) 1800 mg', 'L-Karnitinas (3a910) 300 mg', 'Vitaminas B1 (3a821) 3 mg', 'Vitaminas B2 (3a825i) 11 mg', 'Biotinas (3a880) 4 mg', 'Folio rūgštis (3a316) 1.4 mg', 'Vitaminas B6 (3a831) 3 mg', 'Kalcio D-Pantotenatas (3a841) 30 mg', 'Niacinamidas (3a315) 38 mg', 'Vitaminas B12 0.12 mg', 'Jodas (3b201) 0.9 mg', 'Organinis cinkas (3b606) 100 mg', 'Organinis manganas (3b504) 45 mg', 'Organinis varis (3b406) 20 mg', 'Organinė geležis (3b106) 88 mg', 'Organinis selenas (3b810) 0.18 mg'],
							feedingGuide: ['Gyvūno Svoris (kg) - Maisto Kiekis (g)', '1-10 -35-140', '10-20 - 140-230', '20-30 - 230-310', '30-40 - 310-380', '40-50 - 380-440', '50-60 - 440-510', '60-70 - 510-570', '70-80 - 570-630', '80-90 - 630-680'],
							desc: {
								firstPart: 'Aukščiausios kokybės begrūdis pašaras yra specialiai sukurtas šunims su jautriu virškinimu ir maisto netoleravimu. Elnienos baltymai sumažina nepalankios reakcijos į maistą riziką, esant jautriam virškinimui ar odos jautrumui.',
								secondPart: 'Tikrasis margainis palaiko kepenų funkciją ir padeda atsispirti virškinimo ar odos uždegimams. Omega-3 riebalų rūgštys, tokios kaip ALR, EPR ir DHR, padidina odos drėkinimą, padeda išlaikyti kailį sveiką ir blizgantį.',
								thirdPart: 'Idealiai tinka šunims, turintiems nepageidaujamų reakcijų į kitų rūšių maistą.',
								keyBenefits: 'Elnienos pagrindu sudarytas receptas, skirtas užkirsti kelią nepageidaujamoms reakcijoms. Daug Omega-3 stiprina plaukų blizgesį ir odos sveikatą. Probiotikai ir antioksidantai skatina imuninę sistemą ir palaiko sveiką virškinimą.',
								ingredients: 'Dehidratuota elniena (36%), bulvės (30%), kokosų aliejus, džiovintas obuolių minkštimas, lašišų aliejus (5%), linų sėmenys (4%), alaus mielės, žirnių miltai, gliukozaminas (300 mg/kg), frukto oligosacharidai (230 mg/kg), chondroitino sulfatas (230 mg/kg), manano oligosacharidai (180 mg/kg), šeriuotoji juka (180 mg/kg), tikrojo margainio sėklos (110 mg/kg), β-gliukanai (60 mg/kg), džiovinta paprastoji sukatžolė (60 mg/kg), džiovinti šaltalankiai (60 mg/kg), probiotikai Lactobacillus helveticus HA - 122 inaktyvuota (15x109 ląstelių/kg).',
							},
					  }
					: {
							analysis: ['Crude protein 25%', 'Crude fat 14%', 'Crude ash 8.5%', 'Crude fiber 3%', 'Moisture 10%', 'Calcium 1.7%', 'Phosphorus 1.2%', 'Sodium 0.2%', 'Omega-3 fatty acids 1%', 'Omega-6 fatty acids 0.8%', 'EPA (20:5 n-3) 0.06%', 'DHA (22:6 n-3) 0.12%'],
							vitPerKg: ['Vitamin A (3a672a) 23000 IU', 'Vitamin D3 (3a671) 1700 IU', 'Vitamin E (3a700) 550 mg', 'Vitamin C (3a312) 350 mg', 'Taurine (3a370) 1500 mg', 'Cholino chloride (3a890) 1800 mg', 'L-Carnitine (3a910) 300 mg', 'Vitamin B1 (3a821) 3 mg', 'Vitamin B2 (3a825i) 11 mg', 'Biotin (3a880) 4 mg', 'Folate acid (3a316) 1.4 mg', 'Vitamin B6 (3a831) 3 mg', 'Calcium D-Pantothenate (3a841) 30 mg', 'Niacinamide (3a315) 38 mg', 'Vitamin B12 0.12 mg', 'Iodine (3b201 ) 0.9 mg', 'Organic zinc (3b606) 100 mg', 'Organic manganese (3b504) 45 mg', 'Organic copper (3b406) 20 mg', 'Organic iron (3b106) 88 mg', 'Organic selenium (3b810 ) 0.18 mg'],
							feedingGuide: ['Body Weight (kg) - Dry Food (g)', '1-10 -35-140', '10-20 - 140-230', '20-30 - 230-310', '30-40 - 310-380', '40-50 - 380-440', '50-60 - 440-510', '60-70 - 510-570', '70-80 - 570-630', '80-90 - 630-680'],
							desc: {
								firstPart: 'The highest quality grain-free feed is specially formulated for dogs with sensitive digestion and food intolerances. Venison protein reduces the risk of an adverse reaction to the food in case of sensitive digestion or skin sensitivity.',
								secondPart: 'True mullein supports liver function and helps fight digestive or skin inflammation. Omega-3 fatty acids such as ALR, EPR and DHA increase skin hydration, helping to keep the coat healthy and shiny.',
								thirdPart: 'Ideal for dogs with adverse reactions to other types of food.',
								keyBenefits: 'Venison-based formula to avoid adverse reaction. Lots of Omega-3 to boosthair sheen and skin health. Probiotics and antioxidants boost the immune system and supportdigestive health.',
								ingredients: "Dehydrated venison (36%), potatoes (30%), coconut oil, dried apple pulp, salmon oil (5%), linseed (4%), brewer's yeast, pea flour, glucosamine (300 mg/kg), fructo-oligosaccharides (230 mg/kg), chondroitin sulfate (230 mg/kg), mannan oligosaccharides (180 mg/kg), briar yucca (180 mg/kg), marigold seeds (110 mg/kg), β-glucans (60 mg ) /kg), dried comfrey (60 mg/kg), dried sea buckthorn (60 mg/kg), probiotics Lactobacillus helveticus HA - 122 inactivated (15x109 cells/kg).",
							},
					  },
		},
	];

	const sliderItems = [
		{
			title: pageLanguage === 'LT' ? 'Bio-Groom Klean Kitty bevandenis šampūnas' : 'Bio-Groom Klean Kitty Waterless Shampoo',
			desc: pageLanguage === 'LT' ? 'Nenuplaunamas, bevandenis šampūnas katėms, kuriame nėra stiprių cheminių medžiagų. Švelnus, nepalieka dirginančių likučių ir turi subalansuotą pH' : 'A no rinse, waterless shampoo for cats which contains no harsh chemicals, leaves no irritating residue, is pH balanced and mild',
			price: '€13.80 (236 ml)',
			img: newsImg1,
		},
		{
			title: pageLanguage === 'LT' ? 'Bio-Groom Extra Body tekstūros suteikiantis šampūnas šunims' : 'Bio-Groom Extra Body Tearless Texturizing Dog Shampoo',
			desc: pageLanguage === 'LT' ? 'Ašarojimo nesukeliantis, tekstūrą suteikiantis šampūnas, sukurtas veislėms dvigubu kailiu. Gryni, natūralūs ingredientai švelniai ir kruopščiai valo ir kondicionuoja odą bei kailį, suteikia tekstūros ir pilnumo' : 'Tearless, texturizing shampoo developed for double coated breeds. The pure, natural ingredients gently and thoroughly cleanse and condition the skin and coat while adding texture, fullness and body',
			price: '€14.80 (355 ml)',
			img: newsImg2,
		},
		{
			title: pageLanguage === 'LT' ? 'Bio-Groom So Gentle hipoalerginis kondicionierius šunims' : 'Bio-Groom So Gentle Hypoallergenic Dog Conditioner',
			desc: pageLanguage === 'LT' ? 'Lengvai nuplaunamas vandeniu ir nedirgina akių. Palengvina šukavimą, apsaugo nuo plaukų slinkimo ir pašalina nemalonų kvapą' : 'Washes off easily with water and does not irritate the eyes. Makes combing easier, prevents hair loss and removes unpleasant odor',
			price: '€12.00 (355 ml)',
			img: newsImg3,
		},
		{
			title: pageLanguage === 'LT' ? 'DRN EnergoDog Pasta maisto papildas šunims' : 'DRN EnergoDog Pasta Food Supplement for Dogs',
			desc: pageLanguage === 'LT' ? 'Palaiko fiziškai intensyvaus aktyvumo šunų fiziologinę energiją ir medžiagų apykaitą sveikimo metu. Taip pat naudingas nusilpusiems ir prastai maitinamiems šunims' : 'Supports the physiological energy and metabolism of physically active dogs during recovery. Also useful for weakened and malnourished dogs',
			price: '€16.30 (15 ml)',
			img: newsImg4,
		},
		{
			title: pageLanguage === 'LT' ? 'Platinum Oral Clean + Care Classic dantų purškalas šunims ir katėms' : 'Platinum Oral Clean + Care Classic Dental Spray for Dogs and Cats',
			desc: pageLanguage === 'LT' ? 'Pašalina dantų akmenis ir palengviną dantenų uždegimus. Kovoja su blogu burnos kvapu. Stiprus rezultatas ir paprastas naudojimas' : 'Removes tartar and relieves gum inflammation. Fights bad breath. Strong result and easy use',
			price: '€30.20 (65 ml)',
			img: newsImg5,
		},
		{
			title: pageLanguage === 'LT' ? 'Bio-Groom ilgai išliekantys laukinio sausmedžio kvepalai šunims' : 'Bio-Groom Wild Honeysuckle Long Lasting Dog Cologne',
			desc: pageLanguage === 'LT' ? 'Prabangus ir kvapnus šunų kvepalų purškiklis. Pagaminta iš geriausių natūralių kvapų. Jūsų augintinis puikiai kvepės ištisas dienas. Jokio stipraus, tiršto kvepalų kvapo. Puikiai tinka tarp maudynių' : 'Luxury and scented dog perfume spray. Made with the finest natural fragrances. Leaves your pet smelling great for days. No overpowering, thick perfume smell. Great for in between baths',
			price: '€11.80 (118 ml)',
			img: newsImg6,
		},
	];

	return (
		<div className='pop-items-container container'>
			<div className='pop-items-left-side'>
				{pageLanguage === 'LT' ? <h3>Populiarios prekės</h3> : <h3>Popular products</h3>}
				<div className='pop-items'>
					{items.map((item, i) => (
						<Item
							item={item}
							key={i}
						/>
					))}
				</div>
			</div>
			<div className='pop-items-right-side'>
				<div className='content'>
					{pageLanguage === 'LT' ? <h3>Naujienos ir pasiūlymai</h3> : <h3>News and offers</h3>}
					<div className='new-item-container'>
						<SlideComp slides={sliderItems}></SlideComp>
					</div>
				</div>
			</div>
			<div className='pop-items-mobile-top'>
				<div className='mobile-main-container'>
					<img
						className='mobile-logos'
						src={logo}
						alt='dog food brand logos'
					/>
					<img
						className='mobile-division'
						src={pageLanguage === 'LT' ? division : divisionEN}
						alt='dog food description'
					/>
					<img
						className='mobile-nose'
						src={nose}
						alt='emoji of dog nose'
					/>
				</div>
			</div>
		</div>
	);
}

export default PopularItems;
