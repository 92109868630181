import {Box, Modal} from '@mui/material';
import React, {useState} from 'react';
import {RxCross1} from 'react-icons/rx';
import {useDispatch, useSelector} from 'react-redux';
import HotIcon from '../../assets/icons/HOTicon.webp';
import NewIcon from '../../assets/icons/NEWicon.webp';
import {setSelectedProduct} from '../../store/generalStore';

function Item({item}) {
	const {pageLanguage, selectedProduct, mobileView} = useSelector((state) => state.generalSlice);

	const dispatch = useDispatch();

	const [open, setOpen] = useState(false);
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		dispatch(setSelectedProduct(null));
		setOpen(false);
	};

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: mobileView ? 450 : 950,
		height: 700,
		bgcolor: 'background.paper',
		boxShadow: 24,
		p: 4,
		paddingTop: '25px',
		overflowY: 'scroll',
		lineHeight: '1.5',
	};

	return (
		<div>
			<div
				className='single-item'
				onClick={() => {
					handleOpen();
					dispatch(setSelectedProduct(item));
				}}
			>
				{item.hot && (
					<img
						className='hot-new-icons'
						src={HotIcon}
						alt='product'
					/>
				)}
				{item.new && (
					<img
						className='hot-new-icons'
						src={NewIcon}
						alt='product'
					/>
				)}
				<img
					src={item.img}
					alt='product'
				/>
				<p>{item.desc}</p>
				<p>€{item.price}</p>
			</div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box sx={style}>
					<RxCross1
						onClick={handleClose}
						className='product-modal-icon'
					/>
					{selectedProduct && (
						<div className='modal-container'>
							<div
								style={{
									gridArea: 'img',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<img
									src={selectedProduct.img}
									alt='product'
									style={{
										width: '170px',
										maxHeight: '240px',
										objectFit: 'contain',
									}}
								/>
							</div>
							<div
								style={{
									gridArea: 'analysis',
									display: 'flex',
									flexDirection: 'column',
									width: mobileView ? '100%' : 'max-content',
									justifySelf: 'center',
									fontSize: '15px',
								}}
							>
								<p style={{fontWeight: '600'}}>{pageLanguage === 'LT' ? 'Sudėtis' : 'Analysis'}</p>
								{selectedProduct.fullDesc.analysis.length !== 0 ? selectedProduct.fullDesc.analysis.map((desc, i) => <p key={i}>{desc}</p>) : <p>--------</p>}
							</div>
							<div
								style={{
									gridArea: 'vitPerKg',
									display: 'flex',
									flexDirection: 'column',
									width: mobileView ? '100%' : 'max-content',
									justifySelf: 'center',
									fontSize: '15px',
								}}
							>
								<p style={{fontWeight: '600'}}>{pageLanguage === 'LT' ? 'Maistiniai Priedai' : 'Nutritional Additives'}</p>
								{selectedProduct.fullDesc.vitPerKg.length !== 0 ? selectedProduct.fullDesc.vitPerKg.map((desc, i) => <p key={i}>{desc}</p>) : <p>--------</p>}
							</div>
							<div
								style={{
									gridArea: 'feedingGuide',
									display: 'flex',
									flexDirection: 'column',
									width: mobileView ? '100%' : 'max-content',
									justifySelf: 'center',
									fontSize: '15px',
								}}
							>
								<p style={{fontWeight: '600'}}>{pageLanguage === 'LT' ? 'Šėrimo Instrukcija' : 'Feeding Guide'}</p>
								{selectedProduct.fullDesc.feedingGuide.length !== 0 ? selectedProduct.fullDesc.feedingGuide.map((desc, i) => <p key={i}>{desc}</p>) : <p>--------</p>}
							</div>
							{selectedProduct.fullDesc.desc.firstPart && (
								<p
									style={{
										gridArea: 'fPart',
										marginTop: '20px',
									}}
								>
									{selectedProduct.fullDesc.desc.firstPart}
								</p>
							)}
							{selectedProduct.fullDesc.desc.secondPart && (
								<p
									style={{
										gridArea: 'sPart',
										marginTop: '20px',
									}}
								>
									{selectedProduct.fullDesc.desc.secondPart}
								</p>
							)}
							{selectedProduct.fullDesc.desc.thirdPart && (
								<p
									style={{
										gridArea: 'tPart',
										marginTop: '20px',
									}}
								>
									{selectedProduct.fullDesc.desc.thirdPart}
								</p>
							)}
							<div
								style={{
									gridArea: 'keyBenefits',
									marginTop: '20px',
								}}
							>
								<p style={{fontWeight: '600'}}>{pageLanguage === 'LT' ? 'Pagrindinės Naudos' : 'Key Benefits'}</p>
								{selectedProduct.fullDesc.desc.keyBenefits && <p>{selectedProduct.fullDesc.desc.keyBenefits}</p>}
							</div>
							<div
								style={{
									gridArea: 'ingredients',
									marginTop: '20px',
								}}
							>
								<p style={{fontWeight: '600'}}>{pageLanguage === 'LT' ? 'Ingredientai' : 'Ingredients'}</p>
								{selectedProduct.fullDesc.desc.ingredients && <p>{selectedProduct.fullDesc.desc.ingredients}</p>}
							</div>
						</div>
					)}
				</Box>
			</Modal>
		</div>
	);
}

export default Item;
