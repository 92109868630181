import React, {useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
import './contacts.css';
import {useSelector} from 'react-redux';
import {Checkbox, FormControlLabel} from '@mui/material';

function ContactForm() {
	const {pageLanguage} = useSelector((state) => state.generalSlice);
	const [emailErrorMsg, setEmailErrorMsg] = useState(false);
	const [textErrorMsg, setTextErrorMsg] = useState(false);
	const [checkbox, setCheckbox] = useState(false);
	const [checkboxError, setCheckboxError] = useState(null);
	const emailRef = useRef();
	const textRef = useRef();

	const formRef = useRef();

	const handleFormSubmit = (e) => {
		e.preventDefault();
		const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

		const formObj = {
			email: emailRef.current.value,
			message: textRef.current.value,
		};

		const isValidEmail = regex.test(formObj.email);

		if (!isValidEmail) return setEmailErrorMsg(true);

		if (formObj.message.length < 5 || formObj.message.length > 150) return setTextErrorMsg(true);

		if (!checkbox) return setCheckboxError(true);

		emailjs.sendForm('service_rlz2k0k', 'template_ax8rdu3', formRef.current, 'hbFiQJ7YJP--0L8g1').then(
			(result) => {
				setEmailErrorMsg(false);
				setTextErrorMsg(false);
				setCheckbox(false);
				setCheckboxError(null);
				emailRef.current.value = '';
				textRef.current.value = '';
			},
			(error) => {
				console.log(error.text);
			}
		);
	};

	return (
		<div className='contacts-form'>
			<form ref={formRef}>
				{emailErrorMsg ? (
					<label
						className='error-message'
						htmlFor='errorMessage'
					>
						{pageLanguage === 'LT' ? 'El. pašto adresas neteisingas' : 'Email address is incorrect'}
					</label>
				) : (
					<label htmlFor='emailAddress'>{pageLanguage === 'LT' ? 'El. pašto adresas' : 'Email address'}</label>
				)}
				<input
					aria-label='email address'
					ref={emailRef}
					name='from_email'
					type='text'
				/>
				{textErrorMsg ? (
					<label
						className='error-message'
						htmlFor='messageEn'
					>
						{pageLanguage === 'LT' ? 'Jūsų žinutė per trumpa' : 'Your message is too short'}
					</label>
				) : (
					<label htmlFor='messageLt'>{pageLanguage === 'LT' ? 'Jūsų žinutė' : 'Your message'}</label>
				)}
				<textarea
					aria-label='message to send'
					ref={textRef}
					name='message'
					id='messageToSend'
					cols='30'
					rows='4'
				></textarea>
				<FormControlLabel
					sx={{
						'& .MuiFormControlLabel-label': {
							fontSize: '12px',
							fontFamily: 'Red Hat Display, sans-serif',
						},
					}}
					control={
						<Checkbox
							checked={checkbox}
							sx={{
								color: ` ${checkboxError ? '#d22c2c' : 'secondary'}`,
							}}
							color='secondary'
							onChange={(e) => {
								setCheckbox(e.target.checked);
							}}
						/>
					}
					label={pageLanguage === 'LT' ? 'Sutinku, kad su manimi susisiektų' : 'I agree to be contacted'}
				/>
			</form>
			<button
				onClick={handleFormSubmit}
				className='form-btn'
			>
				{pageLanguage === 'LT' ? 'Išsiųsti' : 'Send'}
			</button>
		</div>
	);
}

export default ContactForm;
