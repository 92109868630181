import React from "react";
import "./styles/App.css";
import "./styles/variables.css";
import Nav from "./components/Nav/Nav";
import Hero from "./components/Hero/Hero";
import PopularItems from "./components/PopularItems/PopularItems";
import Catalog from "./components/Catalog/Catalog";
import Contacts from "./components/Contacts/Contacts";
import MobileProducts from "./components/Catalog/MobileProducts/MobileProducts";
import HeroPaws from "./components/Hero/HeroPaws/HeroPaws";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import About from "./components/About/About";

const theme = createTheme({
	palette: {
		primary: {
			main: "#F8C81F",
		},
		secondary: {
			main: "#4E4D4D",
		},
	},
});

function App() {
	return (
		<>
			<ThemeProvider theme={theme}>
				<Nav />
				<div className='App'>
					<Hero />
					<HeroPaws />
					<PopularItems />
					<MobileProducts />
					<Catalog />
					<About />
					<Contacts />
				</div>
			</ThemeProvider>
		</>
	);
}

export default App;
